<template>
  <el-dialog
    class="my-dialog"
    :title="title"
    width="900px"
    :close-on-click-modal="false"
    :visible.sync="closeFlag"
    :before-close="closeModel"
    append-to-body
    destroy-on-close
    :show-close="!loading"
    center
  >
    <div class="forms-form" v-loading="loading" element-loading-text="保存中" >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        size="middle"
        label-width="150px"
      >
        <div>
          <el-row>
            <el-col :span="24">
              <el-form-item label="投入品类别：" prop="type">
                <el-select v-model="ruleForm.type" class="full-width" clearable :placeholder="editType == 'look' ? '' : '请选择投入品类别'" :disabled="editType == 'look'">
                  <el-option
                    v-for="(item, index) in selectObj.inputsType"
                    :key="index"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="登记证号：" prop="registerCode">
                <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.registerCode }}</p>
                <el-input v-else class="my-input-btn" v-model="ruleForm.registerCode" maxlength=32 show-word-limit placeholder="请选择登记证号">
                  <el-button slot="append" @click="getRegisterCode" type="primary">查询</el-button>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="投入品名称：" prop="inputsName">
                <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.inputsName }}</p>
                <el-input clearable v-else v-model="ruleForm.inputsName" placeholder="请输入投入品名称" maxlength=16 show-word-limit></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-if="editType != 'add'">
            <el-col :span="24">
              <el-form-item label="投入品登记批次编号：" prop="registerBatch">
                <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.registerBatch }}</p>
                <el-input clearable v-else :disabled="true" v-model="ruleForm.registerBatch" placeholder="自动生成"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="生产厂家：" prop="manufactureFactory">
                <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.manufactureFactory }}</p>
                <el-input clearable v-else v-model="ruleForm.manufactureFactory" placeholder="请输入生产厂家" maxlength=64 show-word-limit type="textarea" :rows="3"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="销售商/门店：" prop="storeName">
                <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.storeName }}</p>
                <el-input :disabled="true" v-else class="my-input-btn" v-model="ruleForm.storeName" placeholder="请选择销售商/门店">
                  <el-button slot="append" @click="chooseType('getsupplylist')" type="primary">选择</el-button>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="购买人：" prop="buyer">
                <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.buyer }}</p>
                <el-input clearable v-else v-model="ruleForm.buyer" placeholder="请输入购买人" maxlength=16 show-word-limit></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="规格型号：" prop="specifications">
                <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.specifications }}</p>
                <el-input clearable v-else v-model="ruleForm.specifications" placeholder="请输入规格型号" maxlength=16 show-word-limit></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="购买数量：" prop="buyNum">
                <p v-if="editType == 'look'" class="view-cont">{{ (ruleForm.buyNum || '') + (ruleForm.buyNumUnit || '')}}</p>
                <el-input clearable v-else v-model="ruleForm.buyNum" placeholder="请输入购买数量" maxlength=14 show-word-limit class="input-with-select">
                  <el-select v-model="ruleForm.buyNumUnit" slot="append" placeholder="请选择">
                    <el-option
                      v-for="(item, index) in selectObj.snumUnit"
                      :key="index"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="购买重量：" prop="buyWeight">
                <p v-if="editType == 'look'" class="view-cont">{{ (ruleForm.buyWeight || '') + (ruleForm.buyWeightUnit || '')}}</p>
                <el-input clearable v-else v-model="ruleForm.buyWeight" placeholder="请输入购买重量" maxlength=14 show-word-limit class="input-with-select">
                  <el-select v-model="ruleForm.buyWeightUnit" slot="append" placeholder="请选择">
                    <el-option
                      v-for="(item, index) in selectObj.saleUnit"
                      :key="index"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="购买日期：" prop="buyDate">
                <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.buyDate }}</p>
                <el-date-picker 
                  v-else
                  :disabled="editType == 'look'"
                  class="date-width"
                  v-model="ruleForm.buyDate"
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="请选择购买日期">
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="购买票据号：" prop="buyBill">
                <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.buyBill }}</p>
                <el-input clearable v-else v-model="ruleForm.buyBill" placeholder="请输入购买票据号" maxlength=16 show-word-limit></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-if="ruleForm.type == '01'">
            <el-col :span="24">
              <el-form-item label="主要成分：" prop="composition">
                <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.composition }}</p>
                <el-input clearable v-else v-model="ruleForm.composition" placeholder="请输入主要成分" maxlength=128 show-word-limit type="textarea" :rows="3"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-if="ruleForm.type == '01'">
            <el-col :span="24">
              <el-form-item label="防治对像：" prop="object">
                <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.object }}</p>
                <el-input clearable v-else v-model="ruleForm.object" placeholder="请输入防治对像" maxlength=128 show-word-limit type="textarea" :rows="3"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="生产日期：" prop="productionDate">
                <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.productionDate }}</p>
                <el-date-picker 
                  v-else
                  :disabled="editType == 'look'"
                  class="date-width"
                  v-model="ruleForm.productionDate"
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="请选择生产日期">
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="保质期（有效期）：" prop="expiration">
                <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.expiration }}</p>
                <el-date-picker 
                  v-else
                  :disabled="editType == 'look'"
                  class="date-width"
                  v-model="ruleForm.expiration"
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="请选择保质期（有效期）">
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-if="ruleForm.type == '01' || ruleForm.type == '03'">
            <el-col :span="24">
              <el-form-item label="安全间隔期（天）/体药期（天）：" prop="quarantinePeriod">
                <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.quarantinePeriod }}</p>
                <el-input clearable v-else v-model="ruleForm.quarantinePeriod" placeholder="请输入安全间隔期（天）/体药期（天）" maxlength=14 show-word-limit></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-if="ruleForm.type == '02'">
            <el-col :span="24">
              <el-form-item label="总养分（>=X%）：" prop="nutrient">
                <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.nutrient }}</p>
                <el-input clearable v-else v-model="ruleForm.nutrient" placeholder="请输入总养分（>=X%）" maxlength=14 show-word-limit></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-if="ruleForm.type == '02'">
            <el-col :span="24">
              <el-form-item label="肥料种类：" prop="manureType">
                <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.manureType }}</p>
                <el-input clearable v-else v-model="ruleForm.manureType" placeholder="请输入肥料种类" maxlength=32 show-word-limit></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="审核人：" prop="auditor">
                <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.auditor }}</p>
                <el-input clearable v-else v-model="ruleForm.auditor" placeholder="请输入审核人" maxlength=16 show-word-limit></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="包装袋及标签或说明书：" prop="url" class="my-upload-files">
                <el-image 
                  v-for="(item, index) in ruleForm.url"
                  :key="index"
                  style="width: 100px; height: 100px"
                  :src="item" 
                  :preview-src-list="ruleForm.url">
                </el-image>
                <div v-if="editType == 'look' && !ruleForm.url.length" class="no-data">无图片</div>
                <div v-if="editType != 'look'" @click="addPics" class="picture-card-plus"><i class="el-icon-plus"></i></div>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-form>
    </div>
    <div class="forms-btns" slot="footer">
      <el-button type="primary" v-if="editType != 'look'" :loading="loading" size="small" @click="submitForm()">保存</el-button>
      <el-button @click="closeModel" :disabled="loading" size="small"> {{ editType == 'look' ? '关闭' : '取消' }}</el-button>
    </div>
    <pics ref="pics" dictCode="410001" :businessCode="ruleForm.batchId" :imgSize="0.5" propName="url" storeTableName="tableName" @getpics="getpics"></pics>
    <supplyList ref="getsupplylist" @getsupplylist="getsupplylist" tempType="02" titleName="投入品供应商"></supplyList>
  </el-dialog>
</template>
<script>
import { request, utils, CONSTPARAMS, baseValidate } from '@/utils/baseUtils'
import supplyList from '@/views/common/supplyList.vue'
import pics from '@/views/common/pics.vue'
let _t = null
export default {
  name: "inputsEdit",
  components: {
    pics,
    supplyList,
  },
  props:{
  },
  data() {
    return {   
      rules: {
        type: [{ required: true,  trigger: "change", message: '请选择投入品类别'}],
        inputsName: [{ required: true,  trigger: "change", message: '请输入投入品名称'}],
        manufactureFactory: [{ required: true,  trigger: "change", message: '请输入生产厂家'}],
        buyer: [{ required: true,  trigger: "change", message: '请输入购买人'}],
        storeName: [{ required: true,  trigger: "change", message: '请选择销售商/门店'}],
        buyDate: [{ required: true,  trigger: "change", message: '请选择购买日期'}],
        url: [{ required: true,  trigger: "change", message: '请上传包装袋及标签或说明书'}],
        buyNum: [{ required: true, trigger: "change", 
          validator: (rule, val, callback) => {
            if(!val){
              callback(new Error("请输入购买数量"))
            }else if(!baseValidate.number(val)){
              callback(new Error("请输入数字"))
            }else{
              callback()
            }
          }
        }],
        buyWeight: [{ required: true, trigger: "change", 
          validator: (rule, val, callback) => {
            if(!val){
              callback(new Error("请输入购买重量"))
            }else if(!baseValidate.number(val)){
              callback(new Error("请输入数字"))
            }else{
              callback()
            }
          }
        }],
        quarantinePeriod: [{ required: false, trigger: "change", 
          validator: (rule, val, callback) => {
            if(val && !baseValidate.number(val)){
              callback(new Error("请输入数字"))
            }else{
              callback()
            }
          }
        }]
      },
      closeFlag: false,
      loading: false,
      title: '',
      editType: '',
      ruleForm: {},
      selectObj: {
        inputsType: CONSTPARAMS.inputsTypes,
        snumUnit: CONSTPARAMS.snumUnit,
        saleUnit: CONSTPARAMS.saleUnit,
      },
    };
  },
  created() {
	},
  mounted() {
    _t = this
  },
  methods: {
    getsupplylist(data){
      this.ruleForm.storeName = data.bodyName || ''
      this.ruleForm.storeId = data.supplierId || ''
    },
    getRegisterCode(){
      let contObj = this.ruleForm,
        type = contObj.type,
        code = contObj.registerCode,
        _this = this
      if(!type){
        _this.message('请先选择投入品类别', "warning")
        return
      }
      if(code){
        request.psfindregistercode({
          registerCode: code || '',
          bodyId: utils.getBodyId() || '',
          type: contObj.type || '',
        }).then(res => {
          if(res.code == 200){
            let obj = Object.assign(contObj, res.data)
            _this.ruleForm.inputsName = obj.inputsName || ''
            _this.ruleForm.manufactureFactory = obj.manufactureFactory || ''
            _this.ruleForm.buyer = obj.buyer || ''
            _this.ruleForm.storeName = obj.storeName || ''
            _this.ruleForm.specifications = obj.specifications || ''
          } else {
            _this.message(res.msg || '', "error")
          }
        }).catch(e => {
          console.log(e)
        })
      }else{
        _this.message('请输入登记证号', "warning")
      }
    },
    chooseType(name){
      this.$refs[name].initData()
    },
    addPics(){
      this.$refs.pics.initData(this.ruleForm.url)
    },
    getpics(data){
      this.ruleForm[data.propName] = data.pics
      this.ruleForm[data.storeTableName] = data.tableName
      this.$refs.ruleForm.validateField(data.propName);
    },
    initData(title, editType, ruleForm){
      this.title = title
      this.editType = editType
      this.ruleForm = ruleForm
      this.closeFlag = true
      this.resetForm()
      editType == 'add' && this.initAdd()
      editType == 'edit' && this.initEdit()
      editType == 'look' && this.initLook()
    },
    initAdd(){
    },
    initEdit(){
    },
    initLook(){
      this.initEdit()
    },
    submitAddNew(){
      this.submitForm(true)
    },
    async submitForm(flag = false) {
      let _this = this,
        editType = this.editType,
        formName = 'ruleForm',
        mesInfo = {
          add: "新增成功！",
          edit: "修改成功！"
        },
        newId = flag ? await utils.getNewId() : ''
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let ways = editType == 'add' ? 'pssaveinput' : 'psupdateinput',
            target = {
            ..._this.ruleForm,
            url: _this.ruleForm.url?.join(',') || '',
            buyDate: _this.ruleForm.buyDate ? _this.ruleForm.buyDate + ' 00:00:00' : '',
            productionDate: _this.ruleForm.productionDate ? _this.ruleForm.productionDate + ' 00:00:00' : '',
            expiration: _this.ruleForm.expiration ? _this.ruleForm.expiration + ' 00:00:00' : ''
          }
          _this.loading = true
          request[ways](target).then((res) => {
            if(res.code == 200){
              _this.message(mesInfo[editType], "success")
              if(flag){
                _this.ruleForm.batchId = newId
              }else{
                _this.closeModel()
                _this.$emit("getData", editType == 'add' ? true : false)
              }
            }else{
              _this.message(res.msg || '', "error")
            }
            _this.loading = false
          }).catch(e => {
            _this.loading = false
          })
        } else {
          _this.message('请先完善信息！', "warning")
          return false
        }
      });
    },
    closeModel() {
      this.closeFlag = false
      // this.$emit("closeModel", false);
    },
    resetForm(formName = 'ruleForm') {
      this.$refs[formName]?.resetFields();
    }
  },
  watch: {
    // 'ruleForm.smpOrgid': {
    //   handler(val){
    //     this.ruleForm.smpOrgid = val
    //     this.queryData()
    //   },
    //   deep: true
    // }
  }
};
</script>

<style lang="scss" scoped>
  .my-dialog ::v-deep .el-dialog {
    margin-top: 5vh !important;
  }
</style>


