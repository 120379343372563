<template>
  <el-dialog
    class="my-dialog"
    :title="title"
    width="1000px"
    :close-on-click-modal="false"
    :visible.sync="closeFlag"
    :before-close="closeModel"
    append-to-body
    center
  >
    <supply ref="supply" @getsupply="getsupply" :titleName="titleName" :tempType="tempType" :isDialog="true"></supply>
  </el-dialog>
</template>
<script>
  import supply from '@/views/common/supply.vue'
  export default {
    name: 'supplyList',
    components: {
      supply
    },
    props:{
      tempType: {
        type: String,
        default: '01' //01为农产品 02为投入品
      },
      titleName: {
        type: '',
        default: ''
      },
    },
    data() {
      return {
        closeFlag: false,
        loading: false,
        title: '选择供应商',
      }
    },
    created() {
    },
    mounted(){
      this.title = this.tempType == '01' ? '选择农产品供应商' : '选择投入品供应商'
    },
    methods: {
      initData(){
        this.closeFlag = true
        this.$refs.supply && this.$refs.supply.reTable()
      },
      closeModel() {
        this.closeFlag = false
      },
      getsupply(data){
        this.$emit('getsupplylist', data)
        this.closeModel()
      }
    },
  }
</script>
<style lang="scss" scoped>
</style>